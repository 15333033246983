import React from 'react';
//Style CSS
import './NewsEventSection.css';
//Images
import News1 from '../../img/malolosblessing/malolos1.jpeg';
import News2 from '../../img/franchiseaexpo2024/header.jpg';
//React Router Dom Link
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const NewsEventSection = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="newsevent-cont container">
            <h2 className="newsevent-title">What's New?</h2>
            <div className="newsevent-flex">
                <div className="newsevent-first">
                    <div className="newsevent-box">
                        <p className="newsevent-date">April 12-14, 2024</p>
                        <Link className="newsevent-link" to={"/newlyopened-midsayap-branch"}>
                            <h3 className="newsevent-link-title">INTERNATIONAL FRANCHISE EXPO 2024: A Premier Event For Franchise Innovators</h3>
                        </Link>
                        <p>The International Franchise Asia Expo 2024 was a landmark event, and LT&G shone once again as one of the leading non-food sector exhibitors. The company presented new offerings, showcased its continued growth, and engaged with a vibrant community of franchising professionals.</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">September 5, 2024</p>
                        <Link className="newsevent-link" to={"/newlyopened-cebusouth-branch"}>
                            <h3 className="newsevent-link-title">LT&G Credit Line Valenzuela Branch Blessing: A New Venture Rooted in Friendship
                            </h3>
                        </Link>
                        <p>LT&G Credit Line Valenzuela Branch is now open to serve you!</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">September 5, 2024</p>
                        <Link className="newsevent-link" to={"/newlyopened-angeles-branch"}>
                            <h3 className="newsevent-link-title">Expanding Success: LT&G Credit Line Meycauayan Branch Blessing
                            </h3>
                        </Link>
                        <p>LT&G Credit Line Meycauayan Branch is now open to serve you!</p>
                        <p>#BranchBlessing #LT&GBranches #BetterLoansBetterLives #LT&GFranchise #MicrolendingFranchise</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">November 9, 2022</p>
                        <Link className="newsevent-link" to={"/newlyopened-gmacavite-branch"}>
                            <h3 className="newsevent-link-title">Newly Opened GMA, Cavite Branch</h3>
                        </Link>
                        <p>LT&G Credit Line GMA, Cavite Branch is now open to serve you!</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">July 26, 2022</p>
                        {/* <Link className="newsevent-link" to={"/franchise-expo-manila"}> */}
                        <h3 className="newsevent-link-title">Newly Opened Cebu City South, Cebu Branch</h3>
                        {/* </Link> */}
                        <p>LT&G Credit Line Cebu City South, Cebu Branch is now open to serve you!</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">October 14 - 16, 2022</p>
                        <Link className="newsevent-link" to={"/franchise-expo-manila"}>
                            <h3 className="newsevent-link-title">International Franchise Expo, Manila 2022</h3>
                        </Link>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">June 4 - 5, 2022</p>
                        <Link className="newsevent-link" to={"/team-building"}>
                            <h3 className="newsevent-link-title">Summer Outing and Team Building</h3>
                        </Link>
                        <p>LT&G Credit Line Summer Outing and Team Building 2022</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">March 4, 2022</p>
                        <Link className="newsevent-link" to={"/newlyopened-sanrafael-branch"}>
                            <h3 className="newsevent-link-title">Newly Opened San Rafael, Bulacan Branch</h3>
                        </Link>
                        <p>LT&G Credit Line San Rafael, Bulacan Branch is now open to serve you!</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">December 4, 2021</p>
                        <Link className="newsevent-link" to={"/newlyopened-santamaria-branch"}>
                            <h3 className="newsevent-link-title">Newly Opened Santa Maria, Bulacan Branch</h3>
                        </Link>
                        <p>LT&G Credit Line Santa Maria, Bulacan Branch is now open to serve you!</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">November 12, 2019</p>
                        <Link className="newsevent-link" to={"/microlend"}>
                            <h3 className="newsevent-link-title">Micro-lending firm gains ground by franchising</h3>
                        </Link>
                        <p>When LT&G Credit Line, a micro-lending company based in Bicol, saw that the company was becoming...</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">July 8, 2019</p>
                        <Link className="newsevent-link" to={"/expoindo"}>
                            <h3 className="newsevent-link-title">Franchise Expo, Indonesia 2019</h3>
                        </Link>
                        <p>LT&G is Proud to be a part of the Philippine Delegation @ the 17th IFRA 2019 Franchise Expo, Indonesia</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">June 22, 2019</p>
                        <Link className="newsevent-link" to={"/expocebu"}>
                            <h3 className="newsevent-link-title">Franchise Expo, Cebu City 2019</h3>
                        </Link>
                        <p>Franchise Negosyo para sa Cebu, BE YOUR OWN BOSS! The First Mircro Lending franchise in the Philippines.</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">May 21, 2019</p>
                        <Link className="newsevent-link" to={"/meetph"}>
                            <h3 className="newsevent-link-title">Meet PH's first Micro Credit Financing on Franchise</h3>
                        </Link>
                        <p>Micro lending in this country is often associated with five-six practices by loan sharks, who charge...</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">May 11, 2019</p>
                        <Link className="newsevent-link" to={"/amazingrace"}>
                            <h3 className="newsevent-link-title">AMAZING RACE 2019</h3>
                        </Link>
                        <p>Amazing race of champions 2019. Albay, Philippines</p>
                    </div>
                    <div className="newsevent-box">
                        <p className="newsevent-date">April 14, 2019</p>
                        <Link className="newsevent-link" to={"/franchiseasia"}>
                            <h3 className="newsevent-link-title">Franchise Asia Philippines 2019</h3>
                        </Link>
                    </div>
                </div>
                <div className="latest-cont">
                    <div className="latest-box">
                        <p className="latest-date">April 12-14, 2024</p>
                        <img className="latest-img" src={News2} alt="img2" />
                        <div className="latest-title-box">
                            <Link className="latest-link" to={"/newlyopened-midsayap-branch"}>
                                <h3 className="latest-title">INTERNATIONAL FRANCHISE EXPO 2024: A Premier Event For Franchise Innovators</h3>
                            </Link>
                            <Link to={"/newlyopened-midsayap-branch"}>Read More</Link>
                        </div>
                    </div>

                    <div className="latest-box">
                        <p className="latest-date">September 1, 2024</p>
                        <img className="latest-img" src={News1} alt="img1" />
                        <div className="latest-title-box">
                            <Link className="latest-link" to={"/newlyopened-cebusouth-branch"}>
                                <h3 className="latest-title">LT&G Malolos Branch Blessing</h3>
                            </Link>
                            <Link to={"/newlyopened-cebusouth-branch"}>Read More</Link>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </motion.div>
    )
}

export default NewsEventSection;