import React from "react";
import './GreatReturns.css';
import { motion } from 'framer-motion';
import { fade } from '../animation';
import { useScroll } from '../useScroll';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import greatReturns from '../../img/greatreturns.png';
import timelessIndustry from '../../img/timelessindustry.png';
import linearOperations from '../../img/linearoperations.png';

const GreatReturns = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="fee-cont container">
            <div>
            <h2 className="fee-main-title">THE FIRST <span className="highlight">MICROLENDING FRANCHISE</span> IN THE COUNTRY</h2>
            <h4 className="fee-main-desc">Partner with us and play an essential role in extending this mission, creating opportunities and fostering prosperity in your area</h4>
            </div>
            
            <Row className="g-3">
                <Col>
                    <Card id="cardheight">
                        <Card.Img variant="top" src={greatReturns} />
                        <Card.Body>
                            <Card.Title id="cardtitle">Great Returns</Card.Title>
                            <Card.Text id= "carddesc">Compared to similarly priced FRANCHISE PACKAGES, LT&G boasts of a relatively faster ROI of 28 MONTHS!</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card id="cardheight">
                        <Card.Img variant="top" src={timelessIndustry} />
                        <Card.Body>
                            <Card.Title id="cardtitle">Timeless Industry</Card.Title>
                            <Card.Text id= "carddesc" >Financial Industry will always be relevant regardless of shifts in the market. You know what they say, "MONEY MAKES THE WORLD GO ROUND".</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card id="cardheight">
                        <Card.Img variant="top" src={linearOperations} />
                        <Card.Body>
                            <Card.Title id="cardtitle">Linear Operations</Card.Title>
                            <Card.Text id= "carddesc" >The Business System is designed to produce maximum results with minimal effort. Say GOODBYE to concerns regarding SPOILAGE, LOW INVENTORY, or EXPENSIVE RENTALS.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </motion.div>
    )
}

export default GreatReturns;
