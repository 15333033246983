import Malolos1 from '../img/malolosblessing/malolos1.jpeg';
import Malolos2 from '../img/malolosblessing/malolos2.jpeg';
import Malolos3 from '../img/malolosblessing/malolos3.jpeg';
import Malolos4 from '../img/malolosblessing/malolos4.jpeg';
import Malolos5 from '../img/malolosblessing/malolos5.jpeg';
import Malolos6 from '../img/malolosblessing/malolos6.jpeg';
import Malolos7 from '../img/malolosblessing/malolos7.jpeg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: Malolos1
    },
    {
        id: 2,
        image: Malolos2
    },
    {
        id: 3,
        image: Malolos3
    },
    {
        id: 4,
        image: Malolos4
    },
    {
        id: 5,
        image: Malolos5
    },
    {
        id: 6,
        image: Malolos6
    },
    {
        id: 7,
        image: Malolos7
    },
];