//Nav link
import { NavLink } from 'react-router-dom';
//React Bootstrap Components
import {Navbar, Nav, Container} from 'react-bootstrap'
//Logo
import Logo from '../img/logo.png'
//style css
import './NavCont.css'
//useState
import { React, useState } from 'react';

const NavCont = () => {
    const [expanded, setExpanded] = useState(false);

    // const [show, setShow] = useState(false);
    // const showDropdown = (e)=>{
    //     setShow(!show);
    // }
    // const hideDropdown = e => {
    //     setShow(false);
    // }

    const fbhandleClick = () => {
        window.open("https://www.facebook.com/mamsltg/?hc_ref=ARS7Zvhy22p9VHz3CguJwLun_Df5Z7YWrsav-j32x_HmLtk5FPQuEYIfGw8tdG7asUE&fref=nf&__tn__=kC-R");
      };

      const ythandleClick = () => {
        window.open("https://www.youtube.com/channel/UCpxZqYEJOLeH0fTfbu0--SA");
      };
    
    return(
        <Navbar expanded={expanded} collapseOnSelect expand="lg" bg="navyblue" sticky="top" variant="dark">
            <Container>
            <Navbar.Brand href="/"><img
                src={Logo}
                className="d-inline-block align-top logo-img"
                alt="React Bootstrap logo"
            />
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <NavLink onClick={() => setExpanded(false)} className="linking link-active" exact to="/">Home</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className="linking link-active" exact to="/about">About Us</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className="linking link-active" to="/branches">Branches</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className="linking link-active" to="/franchising">Franchising</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className="linking link-active" to="/franchising">Security Advisories</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className="linking link-active" to="/contact">Contact Us</NavLink>
                </Nav>
                <Nav className="border-icon">
                    <NavLink onClick={() => setExpanded(false)} className="franchise-now"  to="/contact">Franchise Now!</NavLink>
                </Nav>
                <Nav className="border-icon last">
                    <Nav.Link onClick={fbhandleClick}><i className="fab fa-facebook-f"></i></Nav.Link>
                    <Nav.Link onClick={ythandleClick}><i className="fab fa-youtube"></i></Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavCont;