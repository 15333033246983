import React from "react";
//Style CSS
import './FeeSection.css'

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const FeeSection = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="branchesandcustomer">
            <div className="divbranches">
                <div className="divcount">
                    <h3 className="count">62</h3>
                </div>
                <h2 className="branchesandclients">Branches Nationwide</h2>
            </div>
            <div className="divcount" >
                <div >
                    <h3 className="count">102,099</h3>
                </div>
                <h2 className="branchesandclients">Happy Clients</h2>
            </div>
        </motion.div>
    )
}

export default FeeSection;