import React from "react";
//Home Components
import CarouselCont from '../components/HomeComponents/CarouselCont';
// import Card from '../components/HomeComponents/Card';
import AboutSection from '../components/HomeComponents/AboutSection';
// import FranchiseProg from '../components/FranchiseProg';
// import TeamSection from '../components/HomeComponents/TeamSection';
import FeeSection from '../components/HomeComponents/FeeSection';
import GreatReturns from "../components/HomeComponents/GreatReturns";
import NewsEventSection from '../components/HomeComponents/NewsEventSection';
// import FloaterCont from '../components/Floater'

import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animation';

const Home = () => {
    return (
        <>
            <motion.div
                variants={pageAnimation}
                initial='hidden'
                animate='show'
                exit='exit'
            >
                <CarouselCont />
                 <AboutSection />
                 <FeeSection />
                 <GreatReturns />
                <NewsEventSection />
                {/* <FloaterCont /> */}
                {/* <Card /> */}
                {/* <FeeSection /> */}
                {/* <FranchiseProg /> */}
                {/* <TeamSection /> */}
                
                
            </motion.div>
        </>
    )
}
export default Home;