import React from 'react';
//Style CSS
import './SecondFooter.css';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

const SecondFooter = () => {
    const fbfoothandleClick = () => {
        window.open("https://www.facebook.com/mamsltg/?hc_ref=ARS7Zvhy22p9VHz3CguJwLun_Df5Z7YWrsav-j32x_HmLtk5FPQuEYIfGw8tdG7asUE&fref=nf&__tn__=kC-R");
    };

    const ytfoothandleClick = () => {
        window.open("https://www.youtube.com/channel/UCpxZqYEJOLeH0fTfbu0--SA");
    };
    return (
        <footer class="footer">
          <>
          <Nav className="justify-content-center">
        <Nav.Item >
          <Nav.Link className="footercolor" href="./home">Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="footercolor" eventKey="./about">About Us</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="footercolor" eventKey="./contact">Contact</Nav.Link>
        </Nav.Item>
        <Nav.Item>
           <Nav.Link className="footercolor" eventKey="./faq">FAQs</Nav.Link>
        </Nav.Item>
        <Nav.Item>
           <Nav.Link className="footercolor" eventKey="./about">Privacy Policy</Nav.Link>
        </Nav.Item>
      </Nav>
          </>
  
  <hr class="footer-line"/>
  <div class="footer-bottom">
    <p>&copy; 2024 LT&G Credit Line Corp. All rights reserved.</p>
  </div>
</footer>
    )
}

export default SecondFooter;