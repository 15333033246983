import React from 'react';
//Router
import { Link } from 'react-router-dom';
//styles CSS
import './FranchiseProg.css'

import { motion } from 'framer-motion';
import { fade } from '../components/animation';
import { useScroll } from '../components/useScroll';
import Row from 'react-bootstrap/Row';

const FranchiseProg = () => {
    const [element, controls] = useScroll();
    return(
        <Row>
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="franchise-cont container">
            <h2 className="fr-cont-title">Franchise <span className="title-navy">Programs</span></h2>
            <div className="fr-cont-in">
                <div className="in-fr-cont">
                    <div className="fr-card pre-op-su">
                        <div className="card-content">
                            <h3 className="fr-title">Pre-Opening Support</h3>
                            <p className="fr-body">Franchise Area Mapping & Survey, Staff Recruitment Activities, Branch Office Site Evaluation...</p>
                            <Link to="/preopening" className="fr-btn">View Details</Link>
                        </div>
                    </div>
                    <div className="fr-card op-su">
                        <div className="card-content">
                            <h3 className="fr-title">Opening Support</h3>
                            <p className="fr-body">Detailed Area Mapping, Guided Business Roll Out, Systems Installation and Orientation...</p>
                            <Link to="/opening" className="fr-btn">View Details</Link>
                        </div>
                    </div>
                    <div className="fr-card co-su">
                        <div className="card-content">
                            <h3 className="fr-title">Continuing Support</h3>
                            <p className="fr-body">Periodic Branch Audit, Regular Branch Assistance By Area Supervisors, Daily IT Monitoring...</p>
                            <Link to="/continuing-support" className="fr-btn">View Details</Link>
                        </div>
                    </div>
                </div>
                <div className="in-fr-cont in-cont-2">
                    <div className="fr-card ma-su">
                        <div className="card-content">
                            <h3 className="fr-title">Marketing Support</h3>
                            <p className="fr-body">Opening Week Marketing Drive with LT&G Head Office Team, Social Media Advertising...</p>
                            <Link to="/marketing-support" className="fr-btn">View Details</Link>
                        </div>
                    </div>
                    <div className="fr-card fr-tr">
                        <div className="card-content">
                            <h3 className="fr-title">Franchise Training</h3>
                            <p className="fr-body">Company Orientation, Product Orientation, Staff Orientation, CA Training, Duties & Responsibilities...</p>
                            <Link to="/franchise-training" className="fr-btn">View Details</Link>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
        </Row>
    )
}

export default FranchiseProg;